
.App-header {
  background: #000;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18pt;
  color: #fff;
  font-family: "Poppins";
  font-weight: 800;
}

.content-window {
  width: 95%;
  max-width: 1000px;
  min-width: 350px;
  min-height: 100vh;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 25px;
  padding: 10px;
  border-bottom: 1px solid #222222;
  background: #000;
  width: 100%;
}

.card {
  background-color: #0F0F0F;
  border: 2px solid #292929;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  font: 'Poppins';
  color: #8E8E93;
  font-size: 14pt;
  font-weight: 500;
  width:95%;
  margin: 10px 0px;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-text {
  font: 'Poppins';
  font-weight: 600;
  font-size: 24;
}

.nav-logo {
  display: flex;
  flex-direction: row;
  margin-right: 25px;
}

.nav-item {
  font: 'Poppins';
  font-weight: 500;
  font-size: 14pt;
  color: #fff;
  margin-left: 35px;
  user-select: none;
}

.nav-item:hover {
  color: #FAFF00;
}

.button {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 10pt;
  border: 2px solid;
  border-radius: 5px;
  padding: 3px 8px;
}

h1, h2, h3, h4, p {
  font-family: "Poppins";
  width:fit-content;
}

body, p {
  color: #cccccc;
  font-weight: 400;
  font-size: 12pt;
}

.grid {
  display: grid;
  grid-template-columns: 333px 333px 3333px;
  grid-template-rows: 400px 400px 400px;
}

mark {
  background: none;
  color: #FAFF00;
}

h1, h2, h3, h4, h5 {
  color: white;
  margin-top: 0px;
  margin-bottom: 5px;
  font-weight: 600;
}

span {
  font-weight: 400;
  font-size: 12pt;
}

.link {
  color: #fff;
  font-weight: 500;
}

.link:hover {
  color: #33A1FD;
}